"use client";

import { fetchPCById } from "@/lib/centra/fetchersSetup";
import { ProductStoryblok } from "@/lib/types/storyblok-blok-types";
import { useQuery } from "@tanstack/react-query";
import { ProductUI } from "./ProductUI";

interface Props {
  blok: ProductStoryblok;
}

export const ClientProduct = ({ blok }: Props) => {
  const { data } = useQuery({
    queryKey: ["pc", blok.product!],
    queryFn: () => fetchPCById(blok.product!)
  });

  if (!data) return null;

  return <ProductUI product={data} />;
};
