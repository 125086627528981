import { getStory } from "@/lib/storyblok/storyblokSetup";
import {
  ArticleCategoryStoryblok,
  ArticleStoryblok
} from "@/lib/types/storyblok-blok-types";

export const getFeaturedArticle = async (
  featuredArticle: ArticleCategoryStoryblok["featuredArticle"],
  preview: boolean
) => {
  if (!featuredArticle) return;

  const article = featuredArticle[0];

  if (typeof article === "object") {
    return article;
  }

  const data = await getStory<ArticleStoryblok>({
    slugOrId: article,
    preview,
    uuid: true
  });

  return data;
};

export const getTabs = async (locale: string, preview: boolean) => {
  const articleCategory = await getStory<ArticleCategoryStoryblok>({
    slugOrId: `${locale}/explore`,
    preview
  });

  if (!articleCategory) return;
  return articleCategory.content.tabs;
};
