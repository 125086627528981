import BlokWrap from "@/components/ui/BlokWrap";
import { RichTextRender } from "@/components/ui/RichText";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import getLink from "@/lib/storyblok/utils/getLink";
import { HeroCardStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "next/link";
import styles from "./index.module.css";

const isValidLink = (link: HeroCardStoryblok["link"]) => {
  const newLink = link || {};
  return (
    link?.cached_url ||
    ("url" in newLink && newLink?.url) ||
    ("id" in newLink && newLink.id)
  );
};

const CompWrapper = ({
  children,
  link
}: {
  children: any;
  link: HeroCardStoryblok["link"];
}) => {
  if (isValidLink(link)) {
    const href = getLink(link);
    return (
      <Link href={href} className={styles.contentContainer}>
        {children}
      </Link>
    );
  }
  return <div className={styles.contentContainer}>{children}</div>;
};

export const HeroCard = ({
  blok,
  restProps
}: {
  blok: HeroCardStoryblok;
  restProps: { animation: "fadeY" | "fadeX" | "fadeXinvert" };
}) => {
  const { media, title, description, link, sticky } = blok;
  return (
    <BlokWrap
      data-layout="page"
      editable={blok}
      animated={restProps.animation}
      className={clsx(styles.hero, "hero")}
    >
      <CompWrapper link={link}>
        {media && <StoryblokComponent priority={true} blok={media[0]} />}

        {title && (
          <div className={clsx(styles.content, sticky && styles.stickyContent)}>
            <h2 className={clsx(styles.title, sticky && styles.sticky)}>
              {title}
            </h2>
          </div>
        )}
      </CompWrapper>
      {description && <RichTextRender blok={description} />}
    </BlokWrap>
  );
};
