"use client";
import ReactPlayer from "react-player/lazy";

import useHasHydrated from "@/hooks/useHasHydrated";

import Mute from "@/components/icons/Mute";
import UnMute from "@/components/icons/UnMute";
import { useRef, useState } from "react";
import Skeleton from "../Skeleton";
import styles from "./index.module.css";

interface IVideoProps {
  url?: string;
  controls?: boolean;
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  audio?: boolean;
  [key: string]: unknown;
}

const Video = (props: IVideoProps) => {
  const containerRef = useRef(null);
  const hasHydrated = useHasHydrated();
  const [muted, setMuted] = useState(true);

  if (!props.url || !hasHydrated) return <Skeleton height="100%" />;

  const { controls, autoplay, loop, audio } = props;

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <ReactPlayer
        {...props}
        playsinline
        autoPlay={autoplay}
        controls={controls}
        muted={muted}
        playing
        className="react-player"
        loop={loop}
        config={{
          vimeo: {
            playerOptions: {
              responsive: true,
              playsinline: true,
              background: true,
              autoplay,
              loop,
              muted,
            },
          },
        }}
        fallback={<Skeleton height="100%" />}
      />
      {audio && (
        <div className={styles.mute}>
          <button
            onClick={() => {
              setMuted(!muted);
            }}
            aria-label="unmute video"
          >
            {muted ? <Mute /> : <UnMute />}
          </button>
        </div>
      )}
    </div>
  );
};

export default Video;
