import type {
  ReusableContentStoryblok,
  ReusableStoryblok
} from "@/lib/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { resolveStories } from "@/lib/storyblok/storyblokSetup";

export const ReusableUI = async ({
  blok,
  preview
}: {
  blok: ReusableContentStoryblok;
  preview: boolean;
}) => {
  const { reusable } = blok;
  const reusableStory = await resolveStories<ReusableStoryblok>([
    reusable
  ] as string[]);
  if (!reusable) {
    return null;
  }
  return (
    <>
      {typeof reusable === "string" &&
        reusableStory[reusable]?.content?.body?.map((blok) => {
          return (
            // @ts-expect-error - we know this is a valid component, dont know what Storyblok is generating
            <StoryblokComponent preview={preview} blok={blok} key={blok._uid} />
          );
        })}
    </>
  );
};
