import type { BlokComponents, BlokParameters } from "./blok-component-types";

import components from "./bloks";

export let blokMap: Partial<BlokComponents> = components;

export const setBlokComponents = (newBlokMap: BlokComponents) => {
  blokMap = newBlokMap;
};

export const getBlokComponent = (key: keyof BlokParameters) => {
  return blokMap[key] as any;
};

export const isComponent = (
  key: string | number
): key is keyof BlokParameters =>
  blokMap[key as keyof BlokComponents] !== undefined;
