import BlokWrap from "@/components/ui/BlokWrap";
import { ProductCardList } from "@/features/product/components/ProductCardList";
import type { ProductCard as ProductCardType } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import getLink from "@/lib/storyblok/utils/getLink";
import { ProductListingStoryblok } from "@/lib/types/storyblok-blok-types";
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Link from "next/link";
import styles from "./index.module.css";

const ProductListingUI = ({
  blok,
  productCards
}: {
  blok: ProductListingStoryblok;
  productCards: { card: ProductCardType }[];
}) => {
  const link = getLink(blok?.link?.[0]?.link);
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      className={clsx(styles.container, "product-listing")}
    >
      <div data-layout="page" className={styles.head}>
        {blok.title && <h2>{blok.title}</h2>}
        {blok.link?.[0]?.text && (
          <Link href={link || "/"}>{blok.link?.[0]?.text}</Link>
        )}
      </div>

      <ProductCardList cards={productCards.map((card) => card.card)} />
    </BlokWrap>
  );
};

export default ProductListingUI;
