const errorLogger = (e: Error | unknown, defaultMessage: string) => {
  if (e && typeof e === "object" && "message" in e) {
    console.error(e.message);
    return;
  }
  console.error(defaultMessage);
  return;
};

export const errorLogAndThrow = (
  e: Error | unknown,
  defaultMessage: string
) => {
  errorLogger(e, defaultMessage);

  if (e && typeof e === "object" && "message" in e) throw e;
  if (e && typeof e === "object")
    throw { ...(e as Error), message: defaultMessage };
  throw new Error(defaultMessage);
};

export default errorLogger;
