import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger
} from "@/components/ui/Accordion";
import getLink from "@/lib/storyblok/utils/getLink";
import { MegaMenuSectionStoryblok } from "@/lib/types/storyblok-blok-types";
import Link from "next/link";
import styles from "../../index.module.css";

type MobileMenuModalProps = {
  link: MegaMenuSectionStoryblok;
};

const MobileMenuModal = ({ link }: MobileMenuModalProps) => {
  const href = getLink(link?.link);
  const isNestedSection =
    link.component === "megaMenuSection" && link?.links?.length;
  return (
    <>
      {isNestedSection ? (
        <AccordionRoot>
          <AccordionItem className={styles.accordionItem}>
            <AccordionTrigger
              className={styles.accordionTrigger}
              mobileMenu={true}
            >
              {link.text}
            </AccordionTrigger>
            <AccordionContent className={styles.accordionContent}>
              {link.links?.map((subLink) => {
                let href = getLink(subLink.link);
                return (
                  <div key={subLink._uid}>
                    <Link href={href}>{subLink.text}</Link>
                  </div>
                );
              })}
            </AccordionContent>
          </AccordionItem>
        </AccordionRoot>
      ) : (
        <Link href={href}>{link.text}</Link>
      )}
    </>
  );
};

export default MobileMenuModal;
