import { Suspense } from "react";

import BlokWrap from "@/components/ui/BlokWrap";
import NewsletterSection from "@/components/ui/Footer/Newsletter";
import { NewsletterSignupSectionStoryblok } from "@/lib/types/storyblok-blok-types";
import styles from "./index.module.css";

const NewsletterSignUpSection = ({
  blok,
}: {
  blok: NewsletterSignupSectionStoryblok;
}) => {
  const { title, intro } = blok;
  return (
    <>
      <BlokWrap editable={blok} data-layout={"page"} className={styles.wrapper}>
        <div className={styles.text}>
          <div className={styles.textWrap}>
            <h2>{title && title}</h2>
            <span className={styles.subText}>{intro && intro}</span>
          </div>

          <Suspense>
            <NewsletterSection />
          </Suspense>
        </div>
      </BlokWrap>
    </>
  );
};

export default NewsletterSignUpSection;
