import type { BlokComponents } from "./blok-component-types";

import { TextHero } from "@/components/blocks/atoms/HeroCard";
import { Product } from "@/components/blocks/atoms/Product";
import { ArticleCategory } from "@/components/blocks/layout/ArticleCategory";
import ProductPage from "@/components/blocks/layout/ProductPage";
import { ArticleHero } from "@/components/blocks/sections/ArticleHero/ArticleHero";
import { Articles } from "@/components/blocks/sections/Articles";
import { Content } from "@/components/blocks/sections/Content";
import { ContentCards } from "@/components/blocks/sections/ContentCards";
import { NewHero } from "@/components/blocks/sections/Hero/NewHero";
import HubspotForm from "@/components/blocks/sections/HubspotForm/HubspotForm";
import NewsletterSignUpSection from "@/components/blocks/sections/NewsletterSignupSection/NewsletterSignupSection";
import { PageTopText } from "@/components/blocks/sections/PageTopText";
import { Quote } from "@/components/blocks/sections/Quote";
import { SideBySide } from "@/components/blocks/sections/SideBySide";
import { StandoutText } from "@/components/blocks/sections/StandoutText";
import Video from "@/components/blocks/sections/Video";
import ButtonBlok from "../../components/blocks/atoms/Button/Button";
import { Hero } from "../../components/blocks/atoms/HeroCard";
import Link from "../../components/blocks/atoms/Link/Link";
import MediaElement from "../../components/blocks/atoms/MediaElement";
import ReusableContent from "../../components/blocks/atoms/ReusableContent/ReusableContent";
import Article from "../../components/blocks/layout/Article/Article";
import Plp from "../../components/blocks/layout/PLP";
import Page from "../../components/blocks/layout/Page/Page";
import Accordion from "../../components/blocks/sections/Accordion";
import Media from "../../components/blocks/sections/Media";
import ProductListing from "../../components/blocks/sections/ProductListing";
import HeaderBloks from "../../components/blocks/statics/header/HeaderBloks";
import MegaHeaderBloks from "../../components/blocks/statics/header/MegaHeaderBloks";

const components: BlokComponents = {
  header: HeaderBloks,
  megaHeader: MegaHeaderBloks,
  page: Page,
  productListing: ProductListing,
  heroCard: Hero,
  simpleHero: Hero,
  textHero: TextHero,
  quote: Quote,
  reusableContent: ReusableContent,
  reusable: Page,
  media: Media,
  mediaElement: MediaElement,
  video: Media,
  Article: Article,
  productListingPage: Plp,
  button: ButtonBlok,
  articleCategory: ArticleCategory,
  accordion: Accordion,
  link: Link,
  contentSection: ContentCards,
  product: Product,
  pageTopText: PageTopText,
  content: Content,
  hubspotForm: HubspotForm,
  sideBySide: SideBySide,
  productPage: ProductPage,
  articleHero: ArticleHero,
  articles: Articles,
  hero: NewHero,
  standoutText: StandoutText,
  videoSection: Video,
  newsletterSignupSection: NewsletterSignUpSection,
  NewsLetterPopup: () => null,
};

export default components;
