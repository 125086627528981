import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { ProductPageStoryblok } from "@/lib/types/storyblok-blok-types";
import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

type Props = {
  blok: ProductPageStoryblok;
  preview: boolean;
  locale?: string;
};

const ProductPage = ({ blok, preview, locale }: Props) => {
  return (
    <div
      className={"page-blok"}
      {...storyblokEditable(blok as unknown as SbBlokData)}
    >
      {blok?.body?.map((blok, i) => {
        return (
          <StoryblokComponent
            blok={blok}
            key={blok._uid}
            preview={preview}
            locale={locale}
            pageOrder={i + 1}
          />
        );
      })}
    </div>
  );
};

export default ProductPage;
