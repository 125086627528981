import ProductCard from "@/features/product/components/ProductCard";
import SkeletonPC from "@/features/product/components/ProductCard/SkeletonPC";
import type { ProductCard as ProductCardType } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { range } from "@/lib/utils/range";
import clsx from "clsx";
import React from "react";
import ProductSlides from "./ProductSlides";
import styles from "./index.module.css";

export const ProductCardListSkeleton = ({
  amount = 4,
  className,
  ...rest
}: React.ComponentProps<"div"> & { amount?: number }) => (
  <div
    data-layout="page"
    className={clsx(
      amount > 3 ? styles.cardListLarge : styles.cardListSmall,
      className,
    )}
    {...rest}
  >
    {range(amount).map((key) => (
      <SkeletonPC key={key} />
    ))}
  </div>
);

export const ProductCardList = ({
  cards,
  className,
  ...rest
}: React.ComponentProps<"div"> & {
  cards: ProductCardType[];
}) => {
  if (cards.length > 4) {
    return (
      <div data-layout="full" className={clsx(styles.productSlider)} {...rest}>
        <ProductSlides cards={cards} />
      </div>
    );
  }
  return (
    <div
      data-layout="page"
      className={clsx(styles.wrapper, styles.cardListLarge)}
      {...rest}
    >
      {cards.map((card, key) => (
        <ProductCard key={card?.id || key} product={card} />
      ))}
    </div>
  );
};
