import BlokWrap from "@/components/ui/BlokWrap";
import { PageTopTextStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

type Props = {
  blok: PageTopTextStoryblok;
};

export const PageTopText = ({ blok }: Props) => {
  return (
    <BlokWrap animated="fadeY" editable={blok}>
      <section data-layout="page" className={clsx(styles.pageTop)}>
        <div className={styles.inner}>
          <h1 className={styles.title}>{blok.title}</h1>
          {blok.text && <p className={styles.text}>{blok.text}</p>}
        </div>
      </section>
    </BlokWrap>
  );
};
