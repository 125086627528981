import BlokWrap from "@/components/ui/BlokWrap";
import { QuoteStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

export const Quote = ({
  blok,
  restProps
}: {
  blok: QuoteStoryblok;
  restProps: { column: boolean };
}) => {
  return (
    <BlokWrap data-layout="page" animated="fadeY" editorial editable={blok}>
      <div className={clsx(styles.wrapper, restProps?.column && styles.column)}>
        <div className={styles.inner}>
          <blockquote className={styles.quote}>
            <p>{blok.text}</p>
            <cite>{blok.cite}</cite>
          </blockquote>
        </div>
      </div>
    </BlokWrap>
  );
};
