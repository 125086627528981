"use client";
import ArrowRight from "@/components/icons/ArrowRight";
import Button from "@/components/ui/Button";
import Checkbox from "@/features/checkout/Checkbox/Checkbox";
import { useBoolean } from "@/hooks/useBoolean";
import getLink from "@/lib/storyblok/utils/getLink";
import { FooterStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ChangeEvent, useEffect, useId, useState } from "react";
import styles from "./index.module.css";

interface INewsletterSection {
  newsletterTitle?: string;
  newsletterText?: string;
  privacyPolicyLink?: FooterStoryblok["privacyPolicyLink"];
}

const NewsletterSection = ({
  newsletterTitle,
  newsletterText,
  privacyPolicyLink,
}: INewsletterSection) => {
  const [email, setEmail] = useState("");
  const [state, setState] = useState("idle");
  const path = usePathname();
  const checkboxState = useBoolean(false);
  const link = getLink(privacyPolicyLink);
  const t = useTranslations();
  const uniqueId = useId();

  useEffect(() => {
    setEmail("");
    setState("idle");
  }, [path]);

  const toggleCheckboxState = (e: ChangeEvent<HTMLInputElement>) => {
    checkboxState.toggle();
    setState("");
  };

  return (
    <div className={styles.newsletter}>
      <div>
        <div className={styles.newsletter__inner}>
          {newsletterTitle && (
            <h2 className={styles.newsletter__title}>{newsletterTitle}</h2>
          )}
          {newsletterText && (
            <div>
              <p>{newsletterText}</p>
            </div>
          )}

          {state !== "success" && (
            <form
              action="https://app.rule.io/subscriber-form/subscriber"
              id={`rule-optin-form${uniqueId}`}
              className={styles.form}
              method="POST"
            >
              <input
                type="hidden"
                name="token"
                value="57d4d5df-c0def74-db60606-2673fb4-f8559f8-c1a"
              />
              <input type="hidden" name="tags[]" value="119963" />
              <input type="hidden" name="language" value="1" />
              <div className={styles.inputWrap}>
                <input
                  type="email"
                  className={`${styles.newsletter__input}`}
                  value={email}
                  placeholder="Your e-mail"
                  required
                  name="rule_email"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className={styles.submit}>
                  <Button mode="invert" aria-label="Submit">
                    <ArrowRight />
                  </Button>
                </div>
              </div>

              <div className={styles.checkboxContainer}>
                <Checkbox
                  footerCheckbox={true}
                  checked={checkboxState.value}
                  onChange={toggleCheckboxState}
                  isValid={state !== "checkboxNotChecked"}
                  validate={true}
                  name="confirmation"
                  errorMessage={t("footer.newsletter.checkboxNotChecked")}
                  label={t.rich("footer.newsletter.accept", {
                    link: (chunks) => (
                      <Link
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {chunks}
                      </Link>
                    ),
                  })}
                />
              </div>
            </form>
          )}

          {state === "success" && (
            <p className={clsx(styles.message, styles.success)}>
              {t("footer.newsletter.success")}
            </p>
          )}

          {state === "error" && (
            <p className={styles.message}>{t("footer.newsletter.error")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsletterSection;
