import type { ProductListingPageStoryblok } from "@/lib/types/storyblok-blok-types";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";

const Plp = ({
  blok,
  preview
}: {
  blok: ProductListingPageStoryblok;
  preview: boolean;
}) => {
  if (!blok) return <></>;
  return (
    <>
      {blok.bloks?.map((plpblok) => (
        <StoryblokComponent
          preview={preview}
          blok={plpblok}
          key={plpblok._uid}
        />
      ))}
    </>
  );
};

export default Plp;
