import BlokWrap from "@/components/ui/BlokWrap";
import { ContentCardStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

export const StandoutText = ({
  blok,
  restProps
}: {
  blok: ContentCardStoryblok;
  restProps: { column: boolean };
}) => {
  return (
    <BlokWrap animated="fadeY" editorial editable={blok}>
      <div
        className={clsx(
          styles.wrapper,
          blok.title && styles.hasTitle,
          restProps?.column && styles.column
        )}
        data-layout="page"
      >
        {blok.title && <h2>{blok.title}</h2>}
        <div className={styles.inner}>{blok.text && <p>{blok.text}</p>}</div>
      </div>
    </BlokWrap>
  );
};
