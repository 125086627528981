import type { PageStoryblok } from "@/lib/types/storyblok-blok-types";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import clsx from "clsx";

const Page = ({
  blok,
  preview,
  locale
}: {
  blok: PageStoryblok;
  preview: boolean;
  locale?: string;
}) => {
  if (!blok.body) return <p> Missing body in blok: Page</p>;
  return (
    <div
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        "page-blok",
        blok?.template === "simple" && "layout-simple"
      )}
    >
      {blok?.body?.map((blok, i) => {
        return (
          <StoryblokComponent
            blok={blok}
            key={blok._uid}
            preview={preview}
            locale={locale}
            pageOrder={i + 1}
          />
        );
      })}
    </div>
  );
};

export default Page;
