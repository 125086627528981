import { forwardRef } from "react";

import { getBlokComponent } from "../initialiseBloks";
import type { StoryblokComponentProps } from "./StoryblokComponent";

const ServerComponent = forwardRef<HTMLElement, StoryblokComponentProps>(
  ({ blok, page, locale, ...restProps }, ref) => {
    const Component = getBlokComponent(blok.component);

    if (!Component) {
      const errorMessage = `Component: "${blok.component}" not found`;
      return <div>{errorMessage}</div>;
    }

    if (Component)
      return (
        <Component
          ref={ref}
          blok={blok}
          locale={locale}
          page={page}
          {...restProps}
        />
      );

    return <div>Unknown error while rendering blok: {blok._uid}</div>;
  }
);

ServerComponent.displayName = "ServerComponent";

export default ServerComponent;
