import BlokWrap from "@/components/ui/BlokWrap";
import { fetchPCById } from "@/lib/centra/fetchersSetup";
import { ProductStoryblok } from "@/lib/types/storyblok-blok-types";
import { ClientProduct } from "./ClientProduct";
import { ProductUI } from "./ProductUI";
import styles from "./index.module.css";

type Props = {
  blok: ProductStoryblok;
  preview?: boolean;
  restProps?: { animation: "fadeY" | "fadeX" | "fadeXinvert" };
};

const ServerProduct = async ({ blok }: Props) => {
  const product = await fetchPCById(blok.product!);
  return <ProductUI product={product} />;
};

export const Product = ({ blok, restProps, preview }: Props) => {
  if (!blok.product) return <div>Please select a product</div>;
  return (
    <BlokWrap
      className={styles.wrap}
      editable={blok}
      animated={restProps?.animation}
    >
      {preview && <ClientProduct blok={blok} />}
      {!preview && <ServerProduct blok={blok} />}
    </BlokWrap>
  );
};
