import type { SbBlokData } from "@storyblok/js";
import { storyblokEditable } from "@storyblok/js";

import BlokWrap from "@/components/ui/BlokWrap";
import type { ButtonStoryblok } from "@/lib/types/storyblok-blok-types";

import Button from "@/components/ui/Button";
import getLink from "@/lib/storyblok/utils/getLink";
import styles from "./button.module.css";
const ButtonBlok = ({ blok }: { blok: ButtonStoryblok }) => {
  const { link: links, align } = blok;

  const alignment = () => {
    if (align === "center") {
      return "center";
    }
    if (align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      data-layout={"page"}
      className={styles.wrapper}
      style={{
        alignItems: alignment()
      }}
    >
      {links?.[0] && (
        <>
          <Button href={getLink(links[0]?.link)}>{links[0]?.text}</Button>
        </>
      )}
    </BlokWrap>
  );
};

export default ButtonBlok;
