const ArrowRight = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8H21"
        stroke="#161616"
        strokeMiterlimit="10"
        style={{ stroke: "var(--svg-color)" }}
      />
      <path
        d="M14.25 1.25L21 8L14.25 14.75"
        stroke="#161616"
        strokeMiterlimit="10"
        strokeLinecap="square"
        style={{ stroke: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default ArrowRight;
