const Mute = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        style={{ fill: "var(--svg-color)" }}
        fill="#231f20"
        d="m11.3,1.42l-5.64,5.66H1.41v9.88h4.25l5.64,5.64h2.82V1.42h-2.82Zm.59,19.77l-4.81-4.82-.83-.82h-3.4v-7.05h3.38l.86-.85,4.81-4.8h.82v18.34h-.82Z"
      />
      <polygon
        style={{ fill: "var(--svg-color)" }}
        fill="#231f20"
        points="20.76 12.06 22.77 14.07 21.78 15.06 19.77 13.05 17.76 15.06 16.76 14.07 18.78 12.06 16.79 10.07 17.78 9.06 19.77 11.05 21.78 9.04 22.77 10.05 20.76 12.06"
      />
    </svg>
  );
};

export default Mute;
