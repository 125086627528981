import { ArticleCardType } from "@/components/blocks/layout/ArticleCategory/components/ArticleCard/ArticleCard";
import { StoryblokStory } from "storyblok-generate-ts";
import { render } from "storyblok-rich-text-react-renderer";
import { ArticleStoryblok } from "../types/storyblok-blok-types";

const formatArticleCard = (
  article: StoryblokStory<ArticleStoryblok>
): ArticleCardType => {
  return {
    name: article.content.articleTop?.[0].title || article.name,
    image: article?.content?.media?.[0],
    slug: article.full_slug,
    lead: render(article.content?.lead),
    isFeaturedArticle: false
  };
};

export default formatArticleCard;

export const formatFeaturedArticleCard = (
  featuredArticle: ArticleStoryblok,
  slug: string
): ArticleCardType => {
  return {
    name: featuredArticle.articleTop?.[0].title || featuredArticle.title || "",
    image: featuredArticle.media?.[0],
    slug: slug,
    lead: render(featuredArticle.lead),
    isFeaturedArticle: true
  };
};
