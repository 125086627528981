import { useEffect, useState } from "react";

export function useDebounce<T>(
  value: T,
  delay?: number | ((value: T) => number)
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const resolvedDelay = typeof delay === "function" ? delay(value) : delay;
    const timer = setTimeout(() => setDebouncedValue(value), resolvedDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}
