import BlokWrap from "@/components/ui/BlokWrap";
import { ContentSectionStoryblok } from "@/lib/types/storyblok-blok-types";
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import ContentSlides from "./components/ContentSlides";
import styles from "./index.module.css";

export const ContentCards = async ({
  blok
}: {
  blok: ContentSectionStoryblok;
}) => {
  const { title, content } = blok;
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-blok-background
      data-layout={"full"}
      className={`content-container ${styles.content}`}
    >
      {title && (
        <div className={`mobile ${styles.content__title}`}>
          <h2>{title}</h2>
        </div>
      )}
      <ContentSlides content={content} title={title} />
    </BlokWrap>
  );
};
