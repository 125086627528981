import clsx from "clsx";
import styles from "./index.module.css";

type ArticleHeroContentProps = {
  title: string;
  text: string;
  byline: string;
};

export const LeftHeroContent = ({
  title,
  text,
  byline
}: ArticleHeroContentProps) => {
  return (
    <>
      <div className={styles.textDefault}>
        <span className={styles.textWrapper}>
          {title && (
            <p className={clsx(styles.title, styles.titleDefault)}>{title}</p>
          )}
          {text && (
            <p className={clsx(styles.text, styles.textDefault)}>{text}</p>
          )}
          {byline && (
            <p className={clsx(styles.byline, styles.bylineDefault)}>
              {byline}
            </p>
          )}
        </span>
      </div>
    </>
  );
};

export const HeroFullTextBottomContent = ({
  title,
  text,
  byline
}: ArticleHeroContentProps) => {
  return (
    <>
      <div className={styles.titleAndBylineBottom}>
        {byline && <p className={styles.byline}>{byline}</p>}
        {title && <p className={styles.title}>{title}</p>}
      </div>

      {text && <p className={clsx(styles.text, styles.textBottom)}>{text}</p>}
    </>
  );
};

export const HeroFullTextRightContent = ({
  title,
  text,
  byline
}: ArticleHeroContentProps) => {
  return (
    <div className={styles.textRight}>
      {byline && <p className={styles.byline}>{byline}</p>}
      {title && <p className={styles.title}>{title}</p>}
      {text && <p className={styles.text}>{text}</p>}
    </div>
  );
};

export const HeroFullTextLeftContent = ({
  title,
  text,
  byline
}: ArticleHeroContentProps) => {
  return (
    <div className={styles.textLeft}>
      {byline && <p className={styles.byline}>{byline}</p>}
      {title && <p className={styles.title}>{title}</p>}
      {text && <p className={styles.text}>{text}</p>}
    </div>
  );
};
