import { storyblokEditable, type SbBlokData } from "@storyblok/react/rsc";

import Video from "@/components/ui/Video";
import FormatStoryblokMedia from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import type { IStoryblokImage } from "@/lib/types/storyblok";
import type {
  MediaElementStoryblok,
  MediaStoryblok,
} from "@/lib/types/storyblok-blok-types";

import styles from "./sb-media.module.css";
import StoryblokImage from "./StoryblokImage";

export interface IStoryBlokMedia extends SbBlokData {
  mobileMedia: IStoryblokImage;
  desktopMedia: IStoryblokImage;
}

export interface StoryblokMediaProps {
  blok: MediaStoryblok | undefined;
}

const MediaElement = ({
  blok,
  restProps,
}: {
  blok: MediaElementStoryblok;
  restProps?: Record<string, any>;
}) => {
  const { desktop: desktopMedia, mobile: mobileMedia } =
    FormatStoryblokMedia(blok);
  if (!blok) return <></>;
  return (
    <div
      data-blok
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={styles.wrapper}
      data-only-desktop={!mobileMedia?.isImage && !mobileMedia?.isVideo}
    >
      {desktopMedia && (
        <div className={styles.desktop}>
          {desktopMedia.isVideo ? (
            <Video
              audio={blok.audio}
              url={desktopMedia.file}
              alt={desktopMedia.alt}
              id={desktopMedia._uid}
              width="100%"
              height="100%"
              loop
              muted
            />
          ) : (
            desktopMedia.isImage && (
              <StoryblokImage
                priority={restProps?.priority ?? true}
                position={restProps?.position}
                media={desktopMedia}
                key={desktopMedia.file}
              />
            )
          )}
        </div>
      )}
      {mobileMedia && (
        <div className={styles.mobile}>
          {mobileMedia.isVideo ? (
            <Video
              audio={blok.audio}
              url={mobileMedia.file}
              alt={mobileMedia.alt}
              id={mobileMedia._uid}
              width="100%"
              height="100%"
              loop
              muted={true}
            />
          ) : (
            mobileMedia.isImage && (
              <StoryblokImage
                priority={restProps?.priority}
                position={restProps?.position}
                media={mobileMedia}
                key={mobileMedia.file}
              />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default MediaElement;
