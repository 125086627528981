"use client";

import { forwardRef } from "react";

import { getBlokComponent } from "../initialiseBloks";
import type { StoryblokComponentProps } from "./StoryblokComponent";

const ClientComponent = forwardRef<HTMLElement, StoryblokComponentProps>(
  ({ blok, preview, page, locale, ...restProps }, ref) => {
    const Component = getBlokComponent(blok.component);

    if (!Component) {
      const errorMessage = `Component: "${blok.component}" not found`;
      return <div>{errorMessage}</div>;
    }

    return (
      <Component
        ref={ref}
        blok={blok}
        preview
        page={page}
        locale={locale}
        {...restProps}
      />
    );
  }
);

ClientComponent.displayName = "StoryblokClientComponent";

export default ClientComponent;
