import getLink from "@/lib/storyblok/utils/getLink";
import { ArticlesStoryblok } from "@/lib/types/storyblok-blok-types";
import { ArticlesUI } from "./ArticlesUI";
import { ClientArticles } from "./ClientArticles";
import { getManualArticles } from "./actions";

export const ServerArticles = async ({
  blok,
  preview
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
}) => {
  const articles = await getManualArticles(blok?.articles, preview);

  const link = getLink(blok?.link?.[0]?.link);
  return (
    <ArticlesUI articles={articles} blok={blok} preview={preview} link={link} />
  );
};

export const Articles = ({
  blok,
  preview
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
}) => {
  if (preview) {
    return <ClientArticles blok={blok} preview />;
  }
  return <ServerArticles blok={blok} preview />;
};
