import Link from "@/components/ui/Link";
import getLink from "@/lib/storyblok/utils/getLink";
import { LinkStoryblok } from "@/lib/types/storyblok-blok-types";

const LinkBlok = ({
  blok,
  className,
  ...rest
}: {
  blok: LinkStoryblok;
  className?: string;
}) => {
  const text = blok.text;
  const link = getLink(blok.link);

  return (
    <Link {...rest} className={className} href={link}>
      {text}
    </Link>
  );
};

export default LinkBlok;
