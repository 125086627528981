import type { MultilinkStoryblok } from "@/lib/types/storyblok-blok-types";

const formatUrlLink = (link: MultilinkStoryblok) => {
  if ("url" in link) return link.url;
  throw new Error("Link is not an asset or url link");
};

const checkIfInfoPage = (link: string) => {
  const infoPagesRegex = /\/info-pages(\/.+)$/i;
  const isInfoPage = link.match(infoPagesRegex);
  const url = isInfoPage && isInfoPage[1] ? `/${isInfoPage[1]}` : null;

  return url;
};

const checkIfHome = (link: string) => {
  const homeRegex = /^\/?home(?![a-zA-Z])/i;
  const isHome = link.match(homeRegex);
  const url = isHome && isHome[0] ? "/" : null;

  return url;
};
const getStoryUrl = (link: MultilinkStoryblok) => {
  if ("story" in link) return link.story?.full_slug || "";
  if (link.linktype === "story") return link.cached_url || "";
  throw new Error("Link is not a story link");
};

const formatStoryLink = (link: MultilinkStoryblok) => {
  let url = getStoryUrl(link);
  if (checkIfInfoPage(url)) return checkIfInfoPage(url) as string;
  if (checkIfHome(url)) return checkIfHome(url) as string;
  if (url.startsWith("en/")) {
    url = url.slice(3);
  }
  return url.startsWith("/") ? url : `/${url}`;
};

type EmailLink = {
  linktype: "email";
  email: string;
};

const isEmail = (link?: MultilinkStoryblok): link is EmailLink => {
  return (link as EmailLink)?.linktype === "email";
};

const getLink = (link: MultilinkStoryblok | undefined) => {
  if (link?.linktype === "url" || link?.linktype === "asset") {
    return formatUrlLink(link) ?? "";
  }
  if (link?.linktype === "story") {
    return formatStoryLink(link) ?? "";
  }

  if (isEmail(link)) {
    return link?.email ? `mailto:${link?.email}` : "";
  }

  return "";
};

export default getLink;
