import BlokWrap from "@/components/ui/BlokWrap";
import { PATHS } from "@/consts";
import {
  ArticleStoryblok,
  ArticlesStoryblok,
} from "@/lib/types/storyblok-blok-types";
import formatArticleCard from "@/lib/utils/formatArticleCards";
import { default as Link } from "next/link";
import { StoryblokStory } from "storyblok-generate-ts";
import ArticleCard from "../../layout/ArticleCategory/components/ArticleCard/ArticleCard";
import styles from "./index.module.css";

export const ArticlesUI = ({
  blok,
  preview,
  link,
  articles,
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
  link?: string;
  articles: StoryblokStory<ArticleStoryblok>[];
}) => {
  const pattern = [1, 3, 2];
  return (
    <BlokWrap data-layout="page" animated="fadeY" editable={blok}>
      <div className={styles.head}>
        {blok.title && <h2 className={styles.title}>{blok.title}</h2>}
        <Link href={link || PATHS.explore} className={styles.link}>
          {blok.link?.[0]?.text || "See all"}
        </Link>
      </div>
      <div className={styles.articleGrid}>
        {articles &&
          articles.map((article, key) => (
            <ArticleCard
              className={styles.card}
              {...formatArticleCard(article)}
              key={article.full_slug}
              variant={pattern[key]}
              inherit
            />
          ))}
      </div>
    </BlokWrap>
  );
};
