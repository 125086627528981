"use client";
import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";
import clsx from "clsx";
import Image from "next/image";

import BlokWrap from "@/components/ui/BlokWrap";
import FormatStoryblokMedia from "@/lib/storyblok/utils/Media/FormatStoryblokMedia";
import type { MediaStoryblok } from "@/lib/types/storyblok-blok-types";

import styles from "./media.module.css";

const checkIsFull = (blok: MediaStoryblok) => {
  return blok.alignment === "full";
};

const FixedImageElement = ({ blok }: { blok: MediaStoryblok }) => {
  const full = checkIsFull(blok);

  const { desktop: desktopMedia, mobile: mobileMedia } = FormatStoryblokMedia(
    blok?.media?.[0]
  );

  const sizes = {
    desktop: {
      width: parseInt(desktopMedia?.width ?? "4000"),
      height: parseInt(desktopMedia?.height ?? "4000")
    },
    mobile: {
      width: parseInt(mobileMedia?.width ?? "2000"),
      height: parseInt(mobileMedia?.height ?? "2000")
    }
  };

  const hasDesktopImage =
    !!desktopMedia?.file && desktopMedia.isImage && !desktopMedia.isVideo;
  const hasMobileImage =
    !!mobileMedia?.file && mobileMedia.isImage && !mobileMedia.isVideo;

  return (
    <>
      {hasDesktopImage && (
        <Image
          src={desktopMedia.file}
          alt={desktopMedia.alt}
          data-no-mobile={!hasMobileImage}
          width={full ? 4000 : sizes.desktop.width}
          height={full ? 4000 : sizes.desktop.height}
          className={clsx(styles.desktop)}
          data-full={full}
        />
      )}

      {hasMobileImage && (
        <Image
          src={mobileMedia.file}
          alt={mobileMedia.alt}
          data-no-mobile={!hasMobileImage}
          width={full ? 4000 : sizes.mobile.width}
          height={full ? 4000 : sizes.mobile.height}
          className={styles.mobile}
        />
      )}
    </>
  );
};

const MediaContent = ({
  blok,
  children
}: {
  children: React.ReactElement;
  blok: MediaStoryblok;
}) => {
  const full = checkIsFull(blok);

  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      className={clsx(
        styles.base,
        full && styles.full,
        blok.alignment === "center" && styles.center,
        blok.alignment === "left" && styles.left,
        blok.alignment === "right" && styles.right,
        "media-blok"
      )}
      data-blok
      data-blok-background
      data-full={full}
      data-alignment={blok.alignment}
      data-layout={full ? "full" : "page"}
      animated={full ? "custom" : "fadeY"}
    >
      {children}
    </BlokWrap>
  );
};

const Media = ({ blok }: { blok: MediaStoryblok }) => {
  return (
    <MediaContent blok={blok}>
      <FixedImageElement blok={blok} />
    </MediaContent>
  );
};

export default Media;
