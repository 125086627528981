import type { SbBlokData } from "@storyblok/react/rsc";
import { storyblokEditable } from "@storyblok/react/rsc";

import BlokWrap from "@/components/ui/BlokWrap";
import type { AccordionStoryblok } from "@/lib/types/storyblok-blok-types";

import { AccordionRoot } from "@/components/ui/Accordion";
import AccordionItemBlok from "./AccordionItem";
import styles from "./index.module.css";

const Accordion = ({ blok }: { blok: AccordionStoryblok }) => {
  return (
    <BlokWrap
      {...storyblokEditable(blok as unknown as SbBlokData)}
      animated="fadeY"
      data-layout={"page"}
      className={`hero-container ${styles.root}`}
    >
      <div className={styles.wrapper}>
        {blok.title && (
          <div className={styles.title}>
            <h2>{blok.title}</h2>
          </div>
        )}
        <AccordionRoot>
          {blok.items &&
            blok.items.map((item) => (
              <AccordionItemBlok item={item} key={item._uid} />
            ))}
        </AccordionRoot>
      </div>
    </BlokWrap>
  );
};

export default Accordion;
