import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/Accordion";
import { RichTextRender } from "@/components/ui/RichText";
import { type AccordionitemStoryblok } from "@/lib/types/storyblok-blok-types";
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import styles from "./index.module.css";

const AccordionItemBlok = ({ item }: { item: AccordionitemStoryblok }) => {
  return (
    <AccordionItem {...storyblokEditable(item as unknown as SbBlokData)}>
      <AccordionTrigger>{item.title}</AccordionTrigger>
      <AccordionContent>
        {item.text && (
          <div className={`${styles.accordionitem__body}`}>
            <RichTextRender blok={item.text} />
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

export default AccordionItemBlok;
