import { MARK_LINK, render } from "storyblok-rich-text-react-renderer";

import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import { RichtextStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "../Link";
import styles from "./index.module.css";

export const RichText = ({
  children,
  className,
  ...rest
}: React.ComponentProps<"div">) => {
  return (
    <div {...rest} className={clsx(styles.richText, className)}>
      {children}
    </div>
  );
};

interface SBRichText {
  blok: RichtextStoryblok | string;
  className?: string;
}

export const RichTextRender = ({ blok, className = "" }: SBRichText) => {
  const content =
    typeof blok === "string" ? (
      <p>{blok}</p>
    ) : (
      render(blok, {
        defaultBlokResolver: (name, props) => (
          <StoryblokComponent blok={{ component: name, ...props }} />
        ),
        markResolvers: {
          [MARK_LINK]: (children, attributes) =>
            attributes.href ? (
              <Link href={attributes.href} target={attributes.target}>
                {children}
              </Link>
            ) : (
              <>{children}</>
            ),
        },
      })
    );

  return <RichText className={className}>{content}</RichText>;
};
