import type {
  ArticleCategoryStoryblok,
  ArticleStoryblok
} from "@/lib/types/storyblok-blok-types";

import { getAllStories, getStory } from "@/lib/storyblok/storyblokSetup";
import { ArticleCategoryUI } from "./ArticleCategoryUI";

export type ArticleCategoryProps = {
  blok: ArticleCategoryStoryblok;
  locale?: string;
  path: string;
  preview?: boolean;
};

const ServerArticleCategory = async ({
  blok,
  preview,
  path,
  locale
}: ArticleCategoryProps) => {
  const featuredArticle = await getFeaturedArticle(
    blok.featuredArticle,
    !!preview
  );

  let articles = await getAllStories<ArticleStoryblok>(path);
  if (articles) {
    articles = articles.filter(
      (article) =>
        !article.is_startpage && article.uuid !== featuredArticle?.uuid
    );
  }

  return (
    <ArticleCategoryUI
      articles={articles}
      blok={blok}
      featuredArticle={featuredArticle}
      tabs={blok.tabs || []}
      locale={locale || ""}
    />
  );
};

export default ServerArticleCategory;

const getFeaturedArticle = async (
  featuredArticle: ArticleCategoryStoryblok["featuredArticle"],
  preview: boolean
) => {
  if (!featuredArticle) return;

  const article = featuredArticle[0];
  if (typeof article === "object") {
    return article;
  }

  return await getStory<ArticleStoryblok>({
    slugOrId: article,
    preview,
    uuid: true
  });
};
