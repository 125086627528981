"use client";
import ArrowDown from "@/components/icons/ArrowDown";
import clsx from "clsx";
import styles from "./index.module.css";

import React, { forwardRef } from "react";

type AccRootProps = Omit<React.ComponentProps<"div">, "color"> & {
  ref?: React.Ref<HTMLDivElement>;
  color?: "white";
};
const Root = forwardRef<HTMLDivElement, AccRootProps>(
  ({ className, color, children, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          styles.root,
          color === "white" && styles.white
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
Root.displayName = "AccordionRoot";

type AccItemProps = React.ComponentProps<"details"> & {
  ref?: React.Ref<HTMLDetailsElement>;
};
const Item = forwardRef<HTMLDetailsElement, AccItemProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <details ref={ref} className={clsx(className, styles.item)} {...rest}>
        {children}
      </details>
    );
  }
);
Item.displayName = "AccordionItem";

type AccTriggerProps = React.ComponentProps<"summary"> & {
  ref?: React.Ref<HTMLElement>;
  icon?: React.ReactNode;
  mobileMenu?: boolean;
};
const Trigger = forwardRef<HTMLElement, AccTriggerProps>(
  ({ className, mobileMenu, children, icon = <ArrowDown />, ...rest }, ref) => {
    return (
      <summary ref={ref} className={clsx(className, styles.trigger)} {...rest}>
        {children}
        {icon && (
          <div
            className={clsx(
              mobileMenu && styles.mobileMenu,
              styles.arrow__down,
              styles.icon
            )}
          >
            {icon}
          </div>
        )}
      </summary>
    );
  }
);
Trigger.displayName = "AccordionTrigger";

type AccContentProps = React.ComponentProps<"div"> & {
  ref?: React.Ref<HTMLDivElement>;
};
const Content = forwardRef<HTMLDivElement, AccContentProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <div ref={ref} className={clsx(className, styles.content)} {...rest}>
        {children}
      </div>
    );
  }
);
Content.displayName = "AccordionContent";

interface AccordionProps extends React.ComponentProps<"div"> {
  items: {
    title: string;
    content: React.ReactNode;
  }[];
  styleProps?: {
    item?: string;
    trigger?: string;
    content?: string;
    color?: "white";
  };
}
const Accordion = ({ items, styleProps, ...rest }: AccordionProps) => {
  return (
    <Root {...rest} color={styleProps?.color}>
      {items?.map((item, i) => {
        return (
          <Item className={styleProps?.item} key={i}>
            <Trigger className={styleProps?.trigger}>{item.title}</Trigger>
            <Content className={styleProps?.item}>{item.content}</Content>
          </Item>
        );
      })}
    </Root>
  );
};

// Exports
export {
  Accordion,
  Content as AccordionContent,
  Item as AccordionItem,
  Root as AccordionRoot,
  Trigger as AccordionTrigger
};
