import clsx from "clsx";
import Image from "next/image";

import type { StoryBlokMedia } from "@/lib/types/storyblok";

import { sizes } from "@/lib/utils/imageSizes";
import styles from "./sb-image.module.css";

interface StoryBlokImageProps {
  media: StoryBlokMedia;
  vwMobile?: number;
  vwDesktop?: number;
  className?: string;
  priority?: boolean;
  position?: string;
}

const StoryblokImage = ({
  media,
  className,
  vwMobile = 100,
  vwDesktop = 100,
  priority,
  position,
}: StoryBlokImageProps) => (
  <Image
    src={media.file}
    alt={media.alt}
    quality={90}
    width={media.width ? +media.width : 0}
    height={media.height ? +media.height : 0}
    className={clsx(styles.image, className)}
    sizes={sizes(vwMobile, vwDesktop)}
    style={{
      objectPosition: media.focus,
      transform: position ? `translateY(${position})` : undefined,
    }}
    priority={priority}
  />
);

export default StoryblokImage;
