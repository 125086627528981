import ArrowRight from "@/components/icons/ArrowRight";
import BlokWrap from "@/components/ui/BlokWrap";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import getLink from "@/lib/storyblok/utils/getLink";
import { HeroStoryblok, LinkStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "next/link";
import { ReactNode } from "react";
import styles from "./index.module.css";

export const NewHero = ({
  blok,
  restProps
}: {
  blok: HeroStoryblok;
  restProps?: {
    column: boolean;
  };
}) => {
  const { media, title, links } = blok;

  return (
    <BlokWrap
      data-layout="page"
      editable={blok}
      animated="fadeY"
      className={clsx(styles.hero, "hero")}
    >
      <div className={styles.contentContainer}>
        {media && <StoryblokComponent priority={true} blok={media[0]} />}

        {title && (
          <div className={styles.content}>
            <div className={styles.sticky}>
              <h1 className={clsx(styles.title)}>{title}</h1>
              {links && (
                <div className={styles.linkWrapper}>
                  {links.map((link, index) => (
                    <div key={index} className={styles.links}>
                      <HeroLink link={link}>
                        <ArrowRight />
                      </HeroLink>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </BlokWrap>
  );
};

const HeroLink = ({
  link,
  children
}: {
  link: LinkStoryblok;
  children: ReactNode;
}) => {
  const href = getLink(link.link);
  return (
    <Link className={styles.link} href={href}>
      {link.text}
      {children}
    </Link>
  );
};
