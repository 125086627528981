import getLink from "@/lib/storyblok/utils/getLink";
import { ArticlesStoryblok } from "@/lib/types/storyblok-blok-types";
import { useQuery } from "@tanstack/react-query";
import { ArticlesUI } from "./ArticlesUI";
import { getManualArticles } from "./actions";

export const ClientArticles = ({
  blok,
  preview
}: {
  blok: ArticlesStoryblok;
  preview?: boolean;
}) => {
  const { data } = useQuery({
    queryKey: ["articles", blok],
    queryFn: async () => {
      const articles = await getManualArticles(blok?.articles, false);
      const link = getLink(blok?.link?.[0]?.link);
      return {
        articles,
        link
      };
    }
  });
  const articles = data?.articles ?? [];
  const link = getLink(blok?.link?.[0]?.link);
  return (
    <ArticlesUI articles={articles} blok={blok} preview={preview} link={link} />
  );
};
