"use client";
import "swiper/css";
import "swiper/css/navigation";

import ArrowDown from "@/components/icons/ArrowDown";
import { ContentCardStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { useRef } from "react";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavigationOptions, PaginationOptions } from "swiper/types";
import styles from "../index.module.css";
import ContentCard from "./Card";

const ContentSlides = ({
  content,
  title
}: {
  title?: string;
  content?: ContentCardStoryblok[];
}) => {
  const paginationRef = useRef<HTMLDivElement>(null!);
  const prevRef = useRef<HTMLButtonElement>(null!);
  const nextRef = useRef<HTMLButtonElement>(null!);

  return (
    <>
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current
        }}
        pagination={{
          el: paginationRef.current
        }}
        modules={[Navigation, A11y]}
        className={styles.swiper}
        // Must be set here as swiper does not init with correct values
        onBeforeInit={(swiper) => {
          swiper.params.navigation ||= {};
          swiper.params.pagination ||= {};

          (swiper.params.navigation as NavigationOptions).prevEl =
            prevRef.current;
          (swiper.params.navigation as NavigationOptions).nextEl =
            nextRef.current;
          (swiper.params.pagination as PaginationOptions).el =
            paginationRef.current;
        }}
      >
        {content?.map((item) => (
          <SwiperSlide className={styles.slide} key={item._uid}>
            <ContentCard blok={item} />
          </SwiperSlide>
        ))}
        <div slot="container-end" className={styles.nav}>
          <div ref={paginationRef} className={styles.pagination} />
          <div className={clsx(styles.navigation)}>
            <button ref={prevRef} id="prev">
              <ArrowDown />
            </button>
            <button ref={nextRef} id="next">
              <ArrowDown />
            </button>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default ContentSlides;
