import type { ReusableContentStoryblok } from "@/lib/types/storyblok-blok-types";

import { ReusableUI } from "./UI";

const Reusable = ({
  blok,
  preview
}: {
  blok: ReusableContentStoryblok;
  preview: boolean;
}) => {
  return <ReusableUI blok={blok} preview={preview} />;
};

export default Reusable;
