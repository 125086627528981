"use client";
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import { type HTMLAttributes } from "react";
import { useInView } from "react-intersection-observer";

export type AnimationType = "fadeY" | "custom" | "fadeX" | "fadeXinvert";
type DivProps = HTMLAttributes<HTMLDivElement> & {
  animated?: AnimationType;
  editable?: {};
  editorial?: boolean;
};

const BlokWrap = ({ animated, editable, editorial, ...props }: DivProps) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  if (animated) {
    return (
      <div
        ref={ref}
        data-blok
        data-editorial={!!editorial}
        data-animated={animated}
        data-view={inView}
        {...(editable
          ? storyblokEditable(editable as unknown as SbBlokData)
          : {})}
        {...props}
      />
    );
  }

  return (
    <div
      {...(editable
        ? storyblokEditable(editable as unknown as SbBlokData)
        : {})}
      data-blok
      {...props}
    />
  );
};

export default BlokWrap;
