"use client";
import ProductCard from "@/features/product/components/ProductCard";
import { type ProductCard as ProductCardType } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import styles from "./index.module.css";
type Props = {
  product: ProductCardType;
};

export const ProductUI = ({ product }: Props) => {
  return (
    <div className={styles.inner}>
      <ProductCard product={product} />
    </div>
  );
};
