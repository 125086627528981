import {
  ArticleCategoryStoryblok,
  ArticleStoryblok,
  LinkStoryblok
} from "@/lib/types/storyblok-blok-types";
import formatArticleCard from "@/lib/utils/formatArticleCards";
import { StoryblokStory } from "storyblok-generate-ts";
import ArticleCard from "./components/ArticleCard/ArticleCard";
import ArticleHero from "./components/ArticleHero";
import styles from "./index.module.css";

export const ArticleCategoryUI = ({
  blok,
  featuredArticle,
  articles,
  tabs,
  locale
}: {
  blok: ArticleCategoryStoryblok;
  featuredArticle?: StoryblokStory<ArticleStoryblok>;
  articles: StoryblokStory<ArticleStoryblok>[];
  tabs: LinkStoryblok[];
  locale: string;
}) => {
  return (
    <main className={styles.articleGrid} data-blok>
      <ArticleHero
        title={blok.title}
        media={blok.media}
        tabs={tabs}
        featuredArticle={featuredArticle?.content}
        featuredArticleSlug={featuredArticle?.full_slug}
        locale={locale}
      />
      <ArticleCards shift={!!featuredArticle} articles={articles} />
    </main>
  );
};

const COLUMNS = 3;
const patterns = [
  [3, 2, 1], // Pattern for rows modulo 3 == 0
  [1, 3, 2], // Pattern for rows modulo 3 == 1
  [2, 1, 3] // Pattern for rows modulo 3 == 2
] as const;

export const ArticleCards = ({
  articles,
  shift
}: {
  articles?: StoryblokStory<ArticleStoryblok>[];
  shift?: boolean;
}) => {
  return articles?.map((article, realIndex) => {
    const indexShiftedBy1 = realIndex + 1;
    const index = shift ? indexShiftedBy1 : realIndex;

    const row = Math.floor(index / COLUMNS);
    const col = index % COLUMNS;
    const pattern = patterns[row % 3];
    const variant = pattern[col];
    return (
      <ArticleCard
        {...formatArticleCard(article)}
        key={article.full_slug}
        variant={variant}
      />
    );
  });
};
