import styles from "@/components/blocks/layout/ArticleCategory/index.module.css";
import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import {
  ArticleStoryblok,
  LinkStoryblok,
  MediaElementStoryblok,
} from "@/lib/types/storyblok-blok-types";
import { formatFeaturedArticleCard } from "@/lib/utils/formatArticleCards";
import clsx from "clsx";
import { Suspense } from "react";
import ArticleCard, { EmptyArticleCard } from "./ArticleCard/ArticleCard";
import ArticleCategoryTabs from "./ArticleCategoryTabs";

export type ArticleHeroProps = {
  title?: string;
  media?: MediaElementStoryblok[];
  tabs?: LinkStoryblok[];
  featuredArticle?: ArticleStoryblok;
  featuredArticleSlug?: string;
  locale: string;
};

const ArticleHero = ({
  title,
  media,
  tabs,
  featuredArticle,
  featuredArticleSlug,
  locale,
}: ArticleHeroProps) => {
  return (
    <>
      <div className={styles.heroLeft}>
        <div className={styles.heroLeft__inner}>
          <h1 className={styles.title}>{!!title && title}</h1>
          <Suspense>
            {tabs && <ArticleCategoryTabs tabs={tabs} locale={locale} />}
          </Suspense>
          <div
            className={clsx(styles.image, styles.mobileImage, styles.heroImage)}
          >
            {media?.[0] && <StoryblokComponent priority blok={media?.[0]} />}
          </div>
          {featuredArticle ? (
            <ArticleCard
              {...formatFeaturedArticleCard(
                featuredArticle,
                featuredArticleSlug ? featuredArticleSlug : "",
              )}
              key={featuredArticleSlug}
              variant={3}
            />
          ) : (
            <EmptyArticleCard />
          )}
        </div>
      </div>

      <div className={styles.desktopImage}>
        {media?.[0] && (
          <StoryblokComponent priority blok={media?.[0]}></StoryblokComponent>
        )}
      </div>
    </>
  );
};

export default ArticleHero;
