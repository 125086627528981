"use client";
import "swiper/css";
import "swiper/css/navigation";

import ArrowDown from "@/components/icons/ArrowDown";
import useDevice from "@/hooks/useDevice";
import type { ProductCard as ProductCardType } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { useRef } from "react";
import { A11y, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavigationOptions } from "swiper/types";
import ProductCard from "../ProductCard/ProductCard";
import styles from "./index.module.css";

const ProductSlides = ({ cards }: { cards: ProductCardType[] }) => {
  const paginationRef = useRef<HTMLDivElement>(null!);
  const prevRef = useRef<HTMLButtonElement>(null!);
  const nextRef = useRef<HTMLButtonElement>(null!);

  const { mobile, isTablet } = useDevice();

  return (
    <>
      <Swiper
        key={mobile.toString()}
        spaceBetween={16}
        slidesPerView={mobile ? 1.6 : isTablet ? 2.6 : 4.6}
        slidesPerGroup={1}
        scrollbar={{ draggable: true }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[Navigation, A11y]}
        className={styles.swiper}
        // Must be set here as swiper does not init with correct values
        onBeforeInit={(swiper) => {
          swiper.params.navigation ||= {};

          (swiper.params.navigation as NavigationOptions).prevEl =
            prevRef.current;
          (swiper.params.navigation as NavigationOptions).nextEl =
            nextRef.current;
        }}
      >
        {cards?.map((card, key) => (
          <SwiperSlide className={styles.slide} key={card?.id || key}>
            <ProductCard product={card} />
          </SwiperSlide>
        ))}
        <div slot="container-end" className={styles.nav}>
          <div className={styles.navigation}>
            <button className={styles.navigationLeft} ref={prevRef} id="prev">
              <ArrowDown />
            </button>
            <button className={styles.navigationRight} ref={nextRef} id="next">
              <ArrowDown />
            </button>
          </div>
        </div>
      </Swiper>
    </>
  );
};

export default ProductSlides;
