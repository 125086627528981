import StoryblokComponent from "@/lib/storyblok/StoryblokComponent/StoryblokComponent";
import getLink from "@/lib/storyblok/utils/getLink";
import Link from "next/link";
import styles from "../index.module.css";

const ContentCard = ({ blok }: any) => {
  const href = getLink(blok.link);
  const { media, title, text } = blok;

  return (
    <div className={`${styles.content__card}`}>
      {media?.[0] && <StoryblokComponent blok={media[0]} />}
      {title && <h3>{title}</h3>}
      {text && href ? <Link href={href}>{text}</Link> : <p>{text}</p>}
    </div>
  );
};
export default ContentCard;
