import type { AssetStoryblok } from "@/lib/types/storyblok-content-types";

export const getStoryblokFile = (media: AssetStoryblok) => {
  const file = media.filename;
  return file;
};

export const getStoryblokAlt = (media: AssetStoryblok) => {
  const alt = media.alt;
  return alt ?? "";
};

export const getStoryblokId = (media: AssetStoryblok) => {
  const id = media.id;
  return id;
};

const getStoryblokSize = (media: AssetStoryblok) => {
  const regex = /\d+x\d+/;
  const size = getStoryblokFile(media)?.match(regex)?.[0];

  return size;
};

export const getStoryblokWidth = (media: AssetStoryblok) => {
  const width = getStoryblokSize(media)?.split("x")[0];
  return width;
};

export const getStoryblokHeight = (media: AssetStoryblok) => {
  const height = getStoryblokSize(media)?.split("x")[1];
  return height;
};

// result should be passed down directly to style={{objectPosition: values}} on next/image
export const getStoryblokFocus = (media: AssetStoryblok) => {
  const focus = media?.focus;

  if (!focus) return "center";

  const width = getStoryblokWidth(media);
  const height = getStoryblokHeight(media);

  const xInt = parseInt(focus?.split(":")[0]?.split("x")[0] ?? "0");
  const yInt = parseInt(focus?.split(":")[0]?.split("x")[1] ?? "0");

  const x = Math.round((xInt / parseInt(width ?? "1")) * 100);
  const y = Math.round((yInt / parseInt(height ?? "1")) * 100);

  return `${x}% ${y}%`;
};
