const UnMute = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        style={{ fill: "var(--svg-color)" }}
        fill="#231f20"
        d="m18.27,19.42l-.66-1.35c.14-.07,3.48-1.77,3.48-5.99s-3.33-5.92-3.48-5.99l.66-1.35c.18.09,4.32,2.16,4.32,7.34s-4.14,7.25-4.32,7.34Z"
      />
      <path
        style={{ fill: "var(--svg-color)" }}
        fill="#231f20"
        d="m17.08,16.64l-.66-1.35c.07-.04,1.85-.95,1.85-3.21s-1.78-3.17-1.85-3.21l.66-1.35c.11.05,2.69,1.34,2.69,4.56s-2.58,4.5-2.69,4.56Z"
      />
      <path
        style={{ fill: "var(--svg-color)" }}
        fill="#231f20"
        d="m11.3,1.41l-5.64,5.66H1.41v9.88h4.25l5.64,5.64h2.82V1.41h-2.82Zm.59,19.77l-4.81-4.82-.83-.82h-3.4v-7.05h3.38l.86-.85,4.81-4.8h.82v18.34h-.82Z"
      />
    </svg>
  );
};

export default UnMute;
