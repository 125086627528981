import type { ProductListingStoryblok } from "@/lib/types/storyblok-blok-types";
import { useQuery } from "@tanstack/react-query";
import ProductListingUI from "./ProductListingUI";
import { fetchFormattedProductCards } from "./actions";

const ClientProductListing = ({ blok }: { blok: ProductListingStoryblok }) => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ["product cards", blok.products],
    queryFn: () => fetchFormattedProductCards(blok.products)
  });

  if (isLoading && !data) return <p>Loading on client, while in storyblok</p>;
  if (isError) return <p>{`Error while loading: ${error}`}</p>;
  if (!data) return null;
  return <ProductListingUI blok={blok} productCards={data} />;
};

export default ClientProductListing;
