import Skeleton from "@/components/ui/Skeleton";
import clsx from "clsx";
import styles from "./index.module.css";

const SkeletonPC = () => {
  return (
    <div className={clsx(styles.card__container, styles.card)}>
      <div style={{ width: "100%" }}>
        <div style={{ aspectRatio: "4/5", width: "100%" }}>
          <Skeleton height="100%" />
        </div>
        <div className={styles.card__description}>
          {/* TODO:add toolTip here if name is too long */}
          <div
            className={clsx(styles["product-name"], "uppercase", "truncate")}
          >
            <Skeleton height="24px" />
          </div>

          <Skeleton height="16px" />
        </div>
      </div>
    </div>
  );
};

export default SkeletonPC;
