import type { ProductListingStoryblok } from "@/lib/types/storyblok-blok-types";

import ClientProductListing from "./ClientProductListing";
import ProductListingUI from "./ProductListingUI";
import { fetchFormattedProductCards } from "./actions";

const ServerProductListing = async ({
  blok
}: {
  blok: ProductListingStoryblok;
}) => {
  const productCards = await fetchFormattedProductCards(blok.products);

  return <ProductListingUI blok={blok} productCards={productCards} />;
};

const ProductListing = ({
  blok,
  preview
}: {
  blok: ProductListingStoryblok;
  preview: boolean;
}) => {
  if (preview) {
    return <ClientProductListing blok={blok} />;
  }
  return <ServerProductListing blok={blok} />;
};

export default ProductListing;
