import BlokWrap from "@/components/ui/BlokWrap";
import { RichTextRender } from "@/components/ui/RichText";
import { ContentCardStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import styles from "./index.module.css";

export const Content = ({
  blok,
  restProps
}: {
  blok: ContentCardStoryblok;
  restProps: { column: boolean };
}) => {
  return (
    <BlokWrap animated="fadeY" editorial editable={blok}>
      <div
        className={clsx(styles.wrapper, restProps?.column && styles.column)}
        data-layout="page"
      >
        <div className={styles.inner}>
          {blok.text && <RichTextRender blok={blok.text} />}
        </div>
      </div>
    </BlokWrap>
  );
};
