import { ArticleCategoryStoryblok } from "@/lib/types/storyblok-blok-types";
import ServerArticleCategory from "./ArticleCategory";
import { ClientArticleCategory } from "./ClientArticleCategory";

export type ArticleCategoryProps = {
  blok: ArticleCategoryStoryblok;
  locale?: string;
  page?: string[];
  preview?: boolean;
};

export const ArticleCategory = ({
  blok,
  locale,
  page,
  preview
}: ArticleCategoryProps) => {
  let path = locale ? locale : "";
  if (page) {
    path += "/" + page.join("/");
  }

  if (preview) {
    return (
      <ClientArticleCategory blok={blok} locale={locale} path={path} preview />
    );
  }

  return <ServerArticleCategory blok={blok} locale={locale} path={path} />;
};
