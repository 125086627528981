"use client";
import Link from "@/components/blocks/atoms/Link";
import styles from "@/components/blocks/layout/ArticleCategory/index.module.css";
import { LinkStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import { usePathname } from "next/navigation";

type ArticleCategoryTabsProps = {
  tabs: LinkStoryblok[];
  locale: string;
};

const ArticleCategoryTabs = ({ tabs, locale }: ArticleCategoryTabsProps) => {
  const pathname = usePathname();
  return (
    <nav className={styles.tabs}>
      {tabs?.map((tab) => (
        <Link
          className={clsx(
            isTabActive(pathname, tab, locale) && styles.activeTab
          )}
          key={tab._uid}
          blok={tab}
        />
      ))}
    </nav>
  );
};

export default ArticleCategoryTabs;

const isTabActive = (pathname: string, tab: LinkStoryblok, locale: string) => {
  return `${locale}${pathname}/` === tab.link.cached_url;
};
